<template>
    <section v-if="!isLoading && !isAuthenticated" class="hero is-fullheight">
        <div class="hero-body">
            <div class="container">
                <div class="content">
                    <div class="columns is-centered is-three-quarters">
                        <div
                            id="loginLeftColumn"
                            class="column hero has-background-info has-text-centered"
                        >
                            <img
                                v-if="isLoading"
                                class="hero-body is-centered"
                                src="../assets/img/tailspin.svg"
                            />
                        </div>
                        <div class="column" :closable="false">
                            <div
                                v-if="$route.name == 'login'"
                                class="content section"
                            >
                                <h1>Grey Light Ordersystem</h1>
                                <form @submit.prevent="performLogin">
                                    <b-field
                                        :label="$t('login.email')"
                                        for="email"
                                    >
                                        <b-input
                                            id="email"
                                            v-model="login"
                                            type="text"
                                            :value="login"
                                            required
                                        />
                                    </b-field>
                                    <b-field
                                        :label="$t('login.password')"
                                        for="password"
                                    >
                                        <b-input
                                            id="password"
                                            v-model="password"
                                            required
                                            :value="password"
                                            type="password"
                                            password-reveal
                                        />
                                    </b-field>
                                    <b-button
                                        type="submit"
                                        :disabled="!login || !password"
                                        class="is-info is-light is-uppercase"
                                        expanded
                                        :value="$t('login.loginButton')"
                                        @click="performLogin"
                                    >
                                        {{ $t('login.loginButton') }}
                                    </b-button>
                                </form>
                                <router-link
                                    :to="{
                                        name: 'reset-password',
                                        params: { reset: 'reset-password' }
                                    }"
                                >
                                    <span class="is-pulled-right">
                                        {{ $t('login.forgotPasswordQuestion') }}
                                    </span>
                                </router-link>
                            </div>
                            <div v-else class="content section">
                                <ResetPassword />
                            </div>
                            <p v-if="response">
                                {{ response.message }}
                            </p>
                            <div class="content">
                                <ErrorMessage
                                    v-if="
                                        hasError && error.response.status != 401
                                    "
                                    :error="errormsg"
                                />
                            </div>
                        </div>
                    </div>
                    <p
                        v-show="authFailed"
                        class="has-text-centered has-text-danger"
                    >
                        {{ authFailed }}
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section v-else>
        <b-loading
            :is-full-page="true"
            :active.sync="isLoading"
            :can-cancel="false"
        />
    </section>
</template>

<script>
import ErrorMessage from '../components/ErrorMessage'
import ResetPassword from '../components/security/ResetPassword'

export default {
    name: 'Login',
    components: {
        ErrorMessage,
        ResetPassword
    },
    data() {
        return {
            login: '',
            password: '',
            statusCode: null,
            auth: false,
            authFailed: ''
        }
    },
    computed: {
        errormsg() {
            if (this.error) return this.error.response.data['hydra:description']
            return this.error
        },
        isLoading() {
            return this.$store.getters['security/isLoading']
        },
        hasError() {
            return this.$store.getters['security/hasError']
        },
        error() {
            return this.$store.getters['security/error']
        },
        isAuthenticated() {
            return this.$store.getters['security/isAuthenticated']
        },
        response() {
            return this.$store.getters['security/response']
        },
        status() {
            return this.$store.getters['security/status']
        }
    },
    methods: {
        async performLogin() {
            if (!this.isLoading) {
                this.authFailed = ''
                this.auth = true
                let payload = {
                    login: this.$data.login,
                    password: this.$data.password
                }

                await this.$store.dispatch('security/login', payload)

                if (!this.$store.getters['security/hasError']) {
                    let redirect = this.$route.query.redirect
                    if (typeof redirect !== 'undefined') {
                        this.$router.push({ path: redirect }).catch(() => {})
                    } else {
                        this.$router.push({ path: '/' }).catch(() => {})
                    }
                } else {
                    this.authFailed = this.$t('login.authFailed')
                }
            }
            this.auth = false
        },
        async performLogout() {
            if (!this.isLoading) {
                this.auth = true

                await this.$store.dispatch('security/logout')
                if (!this.$store.getters['security/hasError']) {
                    this.$router.push({ path: '/' }).catch(() => {})
                }
            }
            this.auth = false
        }
    },
    created() {
        if (this.$route.name === 'logout') {
            this.performLogout()
        }

        let redirect = this.$route.query.redirect

        // this.$store.dispatch('security/checkAuth')

        if (this.$store.getters['security/isAuthenticated']) {
            if (typeof redirect !== 'undefined') {
                this.$router.push({ path: redirect }).catch(() => {})
            } else {
                this.$router.push({ path: '/' }).catch(() => {})
            }
        }
    }
}
</script>

<style scoped>
#loginLeftColumn {
    background-image: url(../assets/img/entry.jpg) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 380px;
    height: 380px;
}
</style>
