<template>
    <div>
        <h1>Dags att återställa lösenordet</h1>
        <div>
            <b-field v-if="!$route.params.token" label="E-post">
                <b-input v-model="email" type="email" />
            </b-field>
            <b-field v-else label="Nytt lösenord">
                <b-input
                    v-model="password"
                    type="password"
                    placeholder="Bekräfta lösenordet"
                    password-reveal
                />
            </b-field>
            <strong>{{ message }}</strong>
            <b-button
                :disabled="!readyToSubmit"
                class="is-info is-light is-uppercase"
                expanded
                @click.prevent="requestReset"
            >
                Återställ lösenordet
            </b-button>
            <p v-show="hasError" class="has-text-danger has-text-centered">
                {{ error.response.data['hydra:description'] }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResetPassword',
    data() {
        return {
            email: '',
            password: '',
            token: this.$route.params.token,
            message: ''
        }
    },
    computed: {
        readyToSubmit() {
            if (this.email) {
                return true
            } else if (this.password) {
                return true
            }
            return false
        },
        isLoading() {
            return this.$store.getters['security/isLoading']
        },
        hasError() {
            return this.$store.getters['security/hasError']
        },
        error() {
            return this.$store.getters['security/error']
        },
        response() {
            return this.$store.getters['security/response']
        },
        status() {
            return this.$store.getters['security/status']
        }
    },
    methods: {
        async requestReset() {
            if (!this.isLoading) {
                let payload = {
                    email: this.$data.email,
                    password: this.$data.password,
                    token: this.$data.token
                }

                if (!this.token) {
                    await this.$store.dispatch('security/requestReset', payload)
                } else {
                    await this.$store.dispatch(
                        'security/changePassword',
                        payload
                    )
                    this.$router
                        .push({
                            name: 'login',
                            params: { message: this.response }
                        })
                        .catch(() => {})
                }
                if (this.status === 201) {
                    this.$router
                        .push({
                            path: '/login',
                            params: { message: this.response }
                        })
                        .catch(() => {})
                }
            }
        }
    }
}
</script>
