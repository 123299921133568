<template>
    <b-message type="is-danger">
        {{ error }}
    </b-message>
</template>

<script>
export default {
    name: 'ErrorMessage',
    props: {
        error: {
            type: Error,
            required: true
        }
    }
}
</script>
